:root {
    --color-primary: #00454f;
    --color-secondary: #19A370;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --font-size-normal: 1rem;
    --font-size-plus: 1.3rem;
    --font-size-big: 1.97rem;
    --button-font-size: 1rem;
    color: var(--color-black);
}

button {
    cursor: pointer;
}

html {
    overflow-x: hidden;
}

p, input{
    font-family:'Montserrat';
    font-size: 100%;
    color: inherit;
}

.App {
    text-align: center;
    color: var(--color-black);
    overflow-x: hidden;
}

.App *,
.App :after,
.App :before {
    box-sizing: border-box;
}

.App p {
    font-family: Montserrat;
    margin: 0;
    line-height: 1.5;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    text-align: left;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

.App .right-container {
    flex: 1;
    position: relative;
    padding: 0 2rem;
    max-width: 100vw;
}

.App .left-container {
    background-color: var(--color-primary);
    height: 100%;
}

.step-container img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.App label.input-container {
    position: relative;
    cursor: pointer;
    font-size: var(--font-size-normal);
    -webkit-user-select: none;
    user-select: none;
    font-family: Dazzle;
    color: var(--color-primary);
}


/* Hide the browser's default radio button */

.input-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
    border: 3px solid var(--color-primary);
    border-radius: 50%;
    box-sizing: border-box;
    height: 21px;
    width: 21px;
}


/* On mouse-over, add a grey background color */

.input-container:hover input~.checkmark {
    background-color: #ccc;
}


/* When the radio button is checked, add a blue background */

.input-container input:checked~.checkmark {
    background-color: var(--color-primary);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    box-sizing: border-box;
}

.input-container input:checked~.checkmark:after {
    display: block;
}

.input-container .checkmark:after {
    background: #fff;
    border-radius: 50%;
    height: 5px;
    left: 8px;
    top: 8px;
    width: 5px;
}

.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.App button,
.ReactModalPortal button {
    font-family: Dazzle;
    color: var(--color-white);
    background-color: var(--color-primary);
    border: none;
    font-size: var(--font-size-normal);
    padding: 1rem;
    margin: 1rem 0;
    white-space: normal;
}

.ReactModalPortal .two-columns {
    gap: 2rem;
}

.App button:disabled,
.App button[disabled] {
    background-color: darkgray;
}

.App button.primary {
    background-color: var(--color-primary);
}

.App button.secondary {
    background-color: var(--color-secondary);
}

.App .link-button {
    background-color: transparent!important;
    border: none;
    color: #000!important;
    cursor: pointer;
    display: inline;
    font-family: Montserrat!important;
    margin: 0;
    margin-left: 1rem;
    font-size: 1rem!important;
    padding: 0;
    font-weight: normal!important;
    text-decoration: underline;
}

.App .link-button.en-savoir-plus {
    font-size: .7rem!important;
    margin-left: 1rem;
}

.App .step-action,
.App .inline-step-actions {
    display: flex;
    gap:30px
}

.revenuFiscal {
    font-family: Dazzle;
    border: 1px solid var(--color-primary);
    font-size: 1rem;
    padding: 0.7rem;
    padding-right: 1rem;
    color: var(--color-primary);
}

.revenuFiscal::after {
    content: 'euroS';
    left: -200px;
}

.revenuFiscal::placeholder {
    color: #CCC;
}


/* Chrome, Safari, Edge, Opera */

input.revenuFiscal::-webkit-outer-spin-button,
input.revenuFiscal::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

.unitForInput {
    display: inline-block;
    margin-left: -5rem;
    width: 5rem;
    font-family: Dazzle;
}

.App h2 {
    font-family: Dazzle;
    color: var(--color-primary);
    margin-top: 1rem;
    font-size: 1.3rem;
}

.step-container {
    display: grid;
    align-items: center;
}

.step-title {
    font-family: Dazzle;
    font-size: var(--font-size-big);
    color: var(--color-primary);
    text-align: left;
}

.two-columns {
    grid-template-columns: 1fr
}

.steps-breadcrumb {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.steps-breadcrumb > div {
    display: flex;
    align-items: center;
}

.steps-breadcrumb p {
    font-family: Dazzle;
    font-size: var(--font-size-normal);
    color: var(--color-primary);
    opacity: 0.3;
    text-align: center;

}

.steps-breadcrumb img {
    width: 2rem;
    height: 1.5rem;
}

.steps-breadcrumb p.active {
    color: var(--color-primary);
    opacity: 1;
}

.rappelDeMonProjet {
    background-color: var(--color-secondary);
    color: var(--color-white);
    padding: 2rem;
    text-align: left;
}

.App h3 {
    font-family: Dazzle;
    font-size: 1.1rem;
    color: var(--color-primary);
}

.rappelDeMonProjet p {
    color: var(--color-primary);
}

span.userData {
    font-family: Dazzle;
    color: white;
    font-size: var(--font-size-normal);
}

i {
    font-weight: bolder;
    color: var(--color-secondary);
}

.green {
    color: var(--color-secondary);
    font-weight: 600;
}


/* Component Autocomplete */

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #008f68;
    color: #fae042;
    cursor: pointer;
    font-weight: 700;
}


/* form */

.twoColumns {
    display: flex;
    max-width: 780px;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    padding: 0;
}

.oneColumn {
    display: flex;
    max-width: 780px;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem auto;
    padding: 0;
}

.step-5 input,
.step-5 select {
    font-family: Montserrat;
    border: 1px solid var(--color-primary);
    font-size: 1rem;
    text-align: center;
    padding: 0.7rem;
    color: var(--color-primary);
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-white);
}

.popup {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 0.9rem;
}


/* Autocomplete */

.searchCity {
    background: white;
    flex: 1;
}

.searchCity .city-search-input,
.searchCity .city-search-input:focus {
    border: none;
    outline: none;
    font-size: 1.2rem;
    width: 100%;
    font-weight: 600;
    color: var(--color-primary);
}

.city-search-input::placeholder {
    color: #CCC;
}

.suggestionList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.suggestionItem {
    cursor: pointer;
    color: black;
}

.listVilles {
    padding: 0.7rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    width: 100%;
    align-items: baseline;
    border: 1px solid var(--color-primary);
    box-sizing: border-box;
}

.villeSelected {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #CCC;
    padding: 0 10px;
}

.villeSelected input,
.villeSelected input:focus {
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
}

.villeSelected button {
    color: black;
    border: none;
    background-color: white;
    margin: 0 0 0 -20px;
    font-size: 2rem;
    margin: 0;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 0;
}

.communes-list-link {
    list-style-image: url(./chevron-circle-right.svg);
    text-align: left;
    margin: 1rem 0;
}

table.communes {
    background-color: transparent;
    width: 100%;
    margin-bottom: 15px;
    font-size: .8rem;
    border-spacing: 0;
    border-collapse: collapse;
}

table.communes td {
    padding: 15px;
    line-height: 1.5;
    vertical-align: top;
    border: 1px solid #ccc;
}

table.communes tbody>tr:nth-child(odd)>td,
table tbody>tr:nth-child(odd)>th {
    background-color: #f7f7f7;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}

div[role=dialog] h2 {
    color: var(--color-primary);
    font-family: Dazzle;
    text-transform: uppercase;
}

div[role=dialog] table {
    margin: 0 auto;
    min-width: 292px;
    max-width: 80%;
}

div[role=dialog] table thead {
    background-color: var(--color-primary);
    color: var(--color-white);
}

div[role=dialog] table thead th {
    padding: .4rem;
}

div[role=dialog] table tbody td {
    padding: 1rem;
    text-align: center;
}

div[role=dialog] table tbody td.montant {
    white-space: nowrap;
}

div[role=dialog] table tbody>tr:nth-child(odd)>td,
div[role=dialog] table tbody>tr:nth-child(odd)>th {
    background-color: #f7f7f7;
}

button.refaireLeTest {
    margin-left: 5rem;
    align-self: flex-end;
    justify-self: end;
}

.App .form-message {
    font-size: 0.6rem;
    color: #00454f;
}


/* Small devices (landscape phones, 576px and up) */

@media (max-width: 740px) {
    div[role="dialog"] {
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        max-height: 100vh!important;
        max-width: 100vw!important;
        height: 100vh!important;
        margin: 0!important;
        padding: 2rem!important;
        inset: inherit!important;
        transform: inherit;
        display: flex!important;
        align-content: flex-start;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
    }
    .ReactModalPortal {
        position: fixed;
        z-index: 999;
    }
}


/* Medium devices (tablet portrait, 768px and up) */

@media (min-width: 768px) {
    .App p {
        font-size: 1.1rem;
    }
    .App h3 {
        font-size: 1.5rem;
    }
    .App h2 {
        font-size: 2rem;
    }
    .App .form-message {
        font-size: 0.7rem;
    }
}


/* Large devices (tablet landscape, 992px and up) */

@media (min-width: 1024px) {
    .App p {
        font-size: 1.2rem;
    }
    .App .right-container {
        padding: 0 3rem;
    }
    .two-columns {
        grid-template-columns: 1fr 1fr;
    }
    /* div[role="dialog"] {
        left: 50%!important;
        right: 20%!important;
        bottom: auto!important;
        margin-right: -50%!important;
        transform: translate(-50%, -50%)!important;
        max-height: 73vh!important;
        max-width: 800px!important;
    } */
}

.citiesModal {
    width: 90vw;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.citiesSearch>input{
    width: 300px;
    padding: 10px 10px;
    border: #00454f 2px solid;
    border-radius: 8px;
    font-size: 14px;
}

.citiesSearch>input:focus{
    outline: none;
}

.citiesTable {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.citiesTable>p {
    padding: 30px 30px;
    text-align: center;
    margin: 0;
    font-size: 20px;
}

.finalField {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:15px
}

.finalForm {
    max-width: 700px;
    color: #00454f;
}

.finalField>#email, .listVilles, .finalField>label, #rayondeRecherche, .checkboxFied, .finalField>button, .errRgpd, .kilometers{
    grid-column: span 2;
}

.finalField>label {
    place-self: start start;
    margin-top: 10px;
}

.checkboxFied {
    margin-bottom: 10px;
}

.finalField>input {
    border: 1px solid var(--color-primary);
    padding: 0.7rem;
    padding-right: 1rem;
    color: var(--color-primary);
    font-size: 1.2rem;
    font-weight: 600;
}

.finalField>input::placeholder {
    color: #ccc;
}

.resume {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.resume>p {
    color:white;
}

#rayondeRecherche {
    color: #00454f;
    background-color: white;
    border: 1px solid var(--color-primary);
    font-size: 1.2rem;
    padding: 0.7rem;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 10px;
}

.finalField>button {
    width: fit-content;
    place-self: center center;
}

.kilometers{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    gap: 8px;
    margin: 20px 0;
}

@media (max-width:1024px) {
    .mainContainer{
        justify-content:flex-start !important;
        gap: 80px;
        height: auto  !important;
        width: auto !important;
    }

    .step-container{
        min-height:fit-content !important;
        max-height: 700px;
    }

    .fieldContainer{
        flex:none
    }

    .imageContainer{
        flex-direction: column;
        min-height:auto !important;
        height:auto !important;
        align-items: center;
    }

    .left-container{
        width: 100vw !important;
        justify-content: center;
    }

    .left-container>img{
        max-width: 400px;
    }

    .input-container{
        font-size: 14px !important;
    }
}

@media (max-width:768px) {
    .finalField {
        grid-template-columns: 1fr;
    }
    
    .finalForm {
        max-width: 80vw;
        width: 80vw;
    }

    .finalField>input, .resume>div>p, .resume>div>p>span, .city-search-input, #rayondeRecherche {
        font-size: 14px !important
    }

    .resume>div>div{
        min-width: 20px !important;
        width: 20px !important;
        min-height: 20px !important;
        height: 20px !important;
    }

    .resume>div>div>img{
        width: 14px !important;
    }
    
    .finalField>#email, .listVilles, .finalField>label, #rayondeRecherche, .checkboxFied, .finalField>button, .errRgpd, .kilometers{
        grid-column: span 1;
    }

    #rayondeRecherche {
        font-size: 16px;
        font-weight: 500;
    }

    .titleStep5{
        font-size: 30px !important;
    }
}

@media (max-width:640px) {
    .mainContainer{
        padding: 40px 20px !important;
        gap: 70px;
    }

    .en-savoir-plus {
        margin-left: 0.5rem !important;
    }
    .step-action{
        flex-direction: column;
        gap:0px !important;
    }
    .citiesSearch{
        margin-top: 25px;
    }
    .citiesSearch>input{
        width: 200px;
    }
    .citiesTable {
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .citiesTable>p {
        font-size: 14px;
    }
}

@media (max-height:800px) {
    .mainContainer{
        padding: 40px 20px !important;
        gap: 70px;
        overflow-y: scroll;
    }
}




/* large desktops, 1280px and up */

@media (min-width: 1200px) {
    .steps-breadcrumb span {
        font-family: Dazzle;
        font-size: var(--font-size-plus);
    }
    .App button,
    .ReactModalPortal button {
        font-size: var(--font-size-plus);
    }
    .App h3 {
        font-size: 1.5rem;
    }
    .App h2 {
        font-size: 2.2rem;
    }
    .App .form-message {
        font-size: 0.8rem;
    }
}


/* larger desktops, 1400px and up */

@media (min-width: 1400px) {}

.flex-row {
    display: flex;
    flex-direction: row;
}

input[type=checkbox] {
    width: auto;
}

label[for=rgpdChecked] {
    text-align: left;
    font-size: 0.8rem;
}